'use client';
import styles from '@/app/ui/navigation/navigation.module.css';
import { ConnectButton as RainbowKitButton } from '@rainbow-me/rainbowkit';
import React from 'react';
import Avatar from '@/app/ui/navigation/avatar';

export default function ConnectButton() {
  return (
    <RainbowKitButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');

        return (
          <div
            className={styles.connectionButton}
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button className={styles.connectButton} onClick={openConnectModal} type="button">
                    CONNECT WALLET
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button className={styles.connectButton} onClick={openChainModal} type="button">
                    CHANGE NETWORK
                  </button>
                );
              }
              return (
                <div>
                  <button className={styles.connectedButton} onClick={openAccountModal} type="button">
                    <Avatar address={account.address} ensImage={account.ensAvatar} size={20} />
                    <div>{account.ensName ? account.ensName.length > 13 ? `${account.ensName.slice(0, 11)}…` : account.ensName : `${account.address.slice(0, 5)}...${account.address.slice(account.address.length - 5)}`}</div>
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </RainbowKitButton.Custom>
  );
}
