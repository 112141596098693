'use client'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { injectedWallet, metaMaskWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets'
import { mainnet, sepolia } from 'wagmi/chains'
import { cookieStorage, createStorage } from 'wagmi'
// import { custom, fallback, http } from 'viem'

const WALLET_CONNECT_PROJECT_ID = '8834a677f1fe3a00537b02f8ae4944b6'

export const config = getDefaultConfig({
  projectId: WALLET_CONNECT_PROJECT_ID,
  appName: 'NFTfi claims portal',
  wallets: [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, walletConnectWallet, injectedWallet],
    },
  ],
  chains: [mainnet, sepolia],
  ssr: true,
  storage: createStorage({
    storage: cookieStorage,
  }),
  pollingInterval: 10_000,

  // ! Too Experimental
  // transports: {
  //   [sepolia.id]: global?.window
  //     ? custom(window.ethereum!)
  //     : fallback([
  //         http('https://rpc.sepolia.org'),
  //         http('https://cloudflare-eth.com/v1/sepolia'),
  //         http('https://ethereum-sepolia-rpc.publicnode.com'),
  //         http('https://sepolia.drpc.org'),
  //         http('https://eth-sepolia.g.alchemy.com/v2/demo'),
  //       ]),
  //   [mainnet.id]: global?.window
  //     ? custom(window.ethereum!)
  //     : fallback([
  //         http('https://cloudflare-eth.com/v1/mainnet'),
  //         http('https://ethereum-rpc.publicnode.com'),
  //         http('https://eth.drpc.org'),
  //         http('https://eth-mainnet.g.alchemy.com/v2/demo'),
  //       ]),
  // },
})
