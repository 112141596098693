'use client'
import { usePathname, useRouter } from 'next/navigation'
import clsx from 'clsx'
import { EnvVarContext } from '@/context'
import { useContext, useEffect } from 'react'
import { useAccount } from 'wagmi'

export default function Wrapper({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const { chainId } = useContext(EnvVarContext)
  const pathname = usePathname()
  const router = useRouter()
  const { chain } = useAccount()

  useEffect(() => {
    const isDynamicCheckRoute = pathname.startsWith('/check/') && pathname.split('/').length > 2
    const isDynamicMintRoute = pathname.startsWith('/minted/') && pathname.split('/').length > 2
    const isDynamicFabrikRoute = pathname.startsWith('/fabrik/') && pathname.split('/').length > 2

    if (chainId && chain && chain?.id !== Number(chainId)) {
      switch (true) {
        case isDynamicCheckRoute:
        case pathname === '/claimed':
        case pathname === '/dashboard':
        case isDynamicFabrikRoute:
        case isDynamicMintRoute:
        case pathname === '/terms':
          router.push('/network')
          break
        default:
          break
      }
    } else if (chainId && chain?.id === Number(chainId)) {
      switch (true) {
        case pathname === '/network':
          router.push('/')
          break
        default:
          break
      }
    }
  }, [chain, pathname, chainId, router])

  return <div className={clsx('wrapper', { 'no-bg-image': pathname.includes('/minted') })}>{children}</div>
}
