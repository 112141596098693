import(/* webpackMode: "eager" */ "/app/node_modules/@rainbow-me/rainbowkit/dist/index.css");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/app/src/app/ui/common/wrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/ui/globals.css");
import(/* webpackMode: "eager" */ "/app/src/app/ui/footer/footer.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/ui/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/Lato-Hairline.woff2\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"./fonts/Lato-Thin.woff2\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"./fonts/Lato-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/Lato-LightItalic.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"./fonts/Lato-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/Lato-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./fonts/Lato-Semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./fonts/Lato-SemiboldItalic.woff2\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"./fonts/Lato-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./fonts/Lato-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"./fonts/Lato-Black.woff2\",\"weight\":\"900\",\"style\":\"normal\"}]}],\"variableName\":\"lato\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/ui/fonts.ts\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-roboto-mono\"}],\"variableName\":\"robotoMono\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/ui/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"700\",\"900\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-roboto-regular\"}],\"variableName\":\"robotoRegular\"}");
import(/* webpackMode: "eager" */ "/app/src/app/ui/icons/open-book.svg");
import(/* webpackMode: "eager" */ "/app/src/app/ui/icons/X.svg");
import(/* webpackMode: "eager" */ "/app/src/app/ui/navigation/navigation.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/env-var-provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/web3-provider.tsx")