import Image from 'next/image';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

export default function Avatar({ address, ensImage, size }: { address: string; ensImage?: string | null | undefined; size: number }) {
  return ensImage ? (
    <Image src={ensImage} width={size} height={size} style={{ borderRadius: size }} alt={`Avatar for ${address}`} />
  ) : (
    <Jazzicon diameter={size} seed={jsNumberForAddress(address)} />
  );
}
