'use client'
import React from 'react'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { cookieToInitialState, WagmiProvider } from 'wagmi'
import { config } from '@/config'
import Avatar from '@/app/ui/navigation/avatar'

const queryClient = new QueryClient()

export function Web3Provider({ children, cookie }: { children: React.ReactNode; cookie: string }) {
  const initialState = cookieToInitialState(config, cookie)

  return (
    <WagmiProvider config={config} {...(initialState ? { initialState } : {})}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider locale={'en-US'} avatar={Avatar}>
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
