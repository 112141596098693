'use client'
import Link from 'next/link'
import Image from 'next/image'
import styles from '@/app/ui/navigation/navigation.module.css'
import ConnectButton from '@/app/ui/navigation/connect-button'
import StackIcon from '@/app/ui/icons/stack.svg'
import { useState, useRef, useEffect } from 'react'
import { usePathname } from 'next/navigation'
import clsx from 'clsx'

export default function Navigation() {
  const [open, setOpen] = useState<boolean>(false)
  const pathname = usePathname()
  const isRoot = pathname === '/'
  const menuRef = useRef<any>(null)

  const Links = () => (
    <>
      <a href="https://blog.nftfifoundation.org/" target="_blank" rel="noopener noreferrer">
        Blog
      </a>
      <a href="https://blog.nftfifoundation.org/nftfi-tokenomics/" target="_blank" rel="noopener noreferrer">
        $NFTFI
      </a>
      {/* {isRoot && (
        <a href="https://twitter.com/NFTfiFoundation" target="_blank" rel="noopener noreferrer">
          X / Twitter
        </a>
      )} */}
      <Link href={'/dashboard'}>Dashboard</Link>
      {!isRoot && (
        <a href="https://thetemporal.net/fabrik" target="_blank" rel="noopener noreferrer">
          Fabrik
        </a>
      )}
    </>
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (open && menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [open])

  return (
    <div className={styles.navigation}>
      <Link href={'/'}>
        <canvas className={clsx(styles.logoAnimation, { [styles.hide]: !isRoot })} id="logo-animation"></canvas>
        <Image className={clsx(styles.logo, { [styles.hide]: isRoot })} src="/nftfi-logo.svg" alt={'NFTfi Logo'} width={180} height={48} />
      </Link>
      <div className={styles.navLinks}>
        <Links />
      </div>
      {!isRoot ? <ConnectButton /> : <div className={styles.connectButtonPlaceholder} />}
      <StackIcon
        className={styles.hamburger}
        onClick={() => {
          setOpen((prev) => !prev)
        }}
      />
      <div ref={menuRef} className={`${styles.slidingMenu} ${open ? styles.open : styles.closed}`}>
        <Links />
        {!isRoot && <ConnectButton />}
      </div>
    </div>
  )
}
