'use client'
import React from 'react'
import { useEffect, useState } from 'react'

const defaultEnv = {
  walletConnectProjectId: '',
  merkleDistributorContractAddress: '',
  genArtContractAddress: '',
  claimableSeason: '',
  claimEndDate: '',
  chainId: '',
  nftfiTokenContractAddress: '',
  distributorTokenLockContractAddress: '',
  externalTokenLockContractAddress: '',
}
export const EnvVarContext = React.createContext(defaultEnv)
EnvVarContext.displayName = 'EnvVarContext'

export function EnvVarProvider({ children }: { children: React.ReactNode }) {
  const [variables, setVariables] = useState(defaultEnv)

  useEffect(() => {
    fetch('/env').then(async (res) => {
      const vars = await res.json()
      setVariables(vars)
    })
  }, [])

  return <EnvVarContext.Provider value={variables}>{children}</EnvVarContext.Provider>
}
